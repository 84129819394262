




























import { Vue, Component, Prop } from "vue-property-decorator";
import { Message } from "element-ui";
import api from "@/api";

@Component({})
export default class extends Vue {
  @Prop({ default: false }) private multiple!: boolean;
  @Prop({ default: false }) private uploadApi!: string;
  @Prop({ default: 'picture' }) private type!: string;
  @Prop({ default: '选取文件' }) private text!: string;
  @Prop({ default: '.jpg, .png' }) private accept!: string;

  fileList = [];
  formData = "";

  delFile() {
    this.fileList = [];
  }
  handleChange(file: any, fileList: any) {
    this.fileList = fileList;

    let formData = new FormData();
    formData.append("file", (this.fileList[0] as any).raw);
    api
      .postU(this.uploadApi, formData)
      .then((res: any) => {
        this.$emit("success", res);
        // this.fileList = [];
      })
      .catch((err: any) => {
        this.handleExceed(err);
      });
  }
  uploadFile(file: any) {

    (this.formData as any).append("file", file.file);
  }
  handleRemove(file: any, fileList: any) {
    console.log(file, fileList);
  }
  handlePreview(file: any) {
    console.log(file);
  }

  submitUpload() {
    let formData = new FormData();
    formData.append("file", (this.fileList[0] as any).raw);
    api
      .postU(this.uploadApi, formData)
      .then((res: any) => {
        this.$emit("success", res);
        this.fileList = [];
      })
      .catch((err: any) => {
        this.handleExceed(err);
      });
  }
  protected handleExceed(res: any) {
    Message.error({
      message: res,
      duration: 5 * 1000,
    });
  }
}
